<template>
    <div>
        <tit-wrap-x-small title="배송선택" />
        <v-select v-model="shippingOption" attach placeholder="선택" return-object item-value="_id" v-bind="{ ...attrs_input, items }" @input="emit" />
        <!-- <div class="txt txt--xs mt-12px">
        </div> -->

        <!-- <tit-wrap-x-small class="mt-12px">
            <template #titleContents>
                <div class="d-flex align-center">
                    <icon-truck-2 class="mr-4px" />
                    <span>지역배송 & 방문설치 서비스</span>
                </div>
            </template>
        </tit-wrap-x-small>
        <v-card outlined rounded="md" color="grey darken-4">
            <div class="pa-10px pa-md-16px white">
                <p class="txt txt--xs txt--dark">지역배송 & 방문설치 서비스는 담당기사님께서 직접 방문드려 설치해주는 서비스입니다. <br class="d-none d-md-block" />기본 설치에 필요한 부속품 가격이 함께 측정된 가격이며, 필요 시 추가비용이 발생할 수 있습니다.</p>
            </div>
        </v-card> -->
    </div>
</template>

<script>
// import IconTruck2 from "@/components/client/icons/icon-truck2.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

import { attrs_input } from "@/assets/variables/attrs";
import { SHIPPING_OPTION_CHARGE_TYPES, SHIPPING_CODES } from "@/assets/variables/constants";
import { initProduct, initShopShippingOption } from "@/assets/variables/inits";

export default {
    components: {
        // IconTruck2,
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Object, default: initShopShippingOption },
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        shippingOption: initShopShippingOption(),

        attrs_input,
    }),
    computed: {
        items() {
            return (this.product?.shipping?.options || []).map((item) => {
                let text = item?.method?.name || SHIPPING_CODES[item?.method?.name]?.text;

                let chargeAmount = 0;

                switch (item.charge.type) {
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value: {
                        chargeAmount = item?.charge?.fixedAmount;
                        break;
                    }
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_PRICES_RANGE.value: {
                        chargeAmount = [...(item.charge.pricesRange || [])].sort((a, b) => a.amount - b.amount)[0]?.amount;
                        break;
                    }
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_TRAVEL_RANGE.value: {
                        chargeAmount = [...(item.charge.travelRange || [])].sort((a, b) => a.amount - b.amount)[0]?.amount;
                        break;
                    }
                }

                if (item.service.isActive && item.service.charge.amount) chargeAmount += item.service.charge.amount;

                if (chargeAmount) text += ` (+${chargeAmount?.format?.()}원)`;

                return {
                    ...item,
                    text,
                };
            });
        },
    },
    methods: {
        init() {
            this.sync();
            if (!this.shippingOption?._id && this.items?.length == 1) {
                this.shippingOption = initShopShippingOption(this.items[0]);
                this.emit();
            }
        },
        sync() {
            this.shippingOption = initShopShippingOption(this.value);
        },
        emit() {
            this.$emit("input", this.shippingOption);
        },
    },
    watch: {
        value() {
            this.sync();
        },
        product() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
</style>