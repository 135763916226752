<template>
    <v-row v-if="items.length" class="row--xxs">
        <v-col cols="12">
            <tit-wrap-x-small title="옵션선택" />
            <template v-for="({ name, values, disabled }, index) in items">
                <v-select
                    v-model="selected[name]"
                    v-bind="{
                        ...props__criterionSelect,

                        items: values,
                        disabled,

                        placeholder: name,
                        clearable: true
                    }"
                    :class="{ 'mt-1 mt-md-2': index != 0 }"
                    @change="emit(index)"
                    :key="name"
                />
            </template>
        </v-col>
    </v-row>
</template>

<script>
import { PRODUCT_OPTION_CRITERIA_TYPES } from "@/assets/variables";

import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

const props__criterionSelect = {
    ["item-value"]: "name",

    ["dense"]: true,
    ["outlined"]: true,
    ["hide-details"]: true,
};

export default {
    components: {
        TitWrapXSmall,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        criteria: { type: Array, default: () => [] },
        findOption: { type: Function, default: () => {} },
        someOption: { type: Function, default: () => {} },
    },
    setup: () => ({
        props__criterionSelect,
    }),
    data: () => ({
        selected: {},
    }),
    computed: {
        color() {
            return this.criteria.find(({ type }) => type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value);
        },
        items() {
            const criteria = this.criteria.filter(({ type }) => type != PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value);
            return criteria.map(({ values, ...criterion }, index) => ({
                ...criterion,

                ////////////////////////////////////////////////////////////////
                // 옵션 설렉트 항목 데이터 바인딩 (이름, 가격)
                ////////////////////////////////////////////////////////////////
                values: values.reduce((values, value) => {
                    let text = value.name;
                    let shows = this.someOption({
                        ...this.selected,
                        [criterion.name]: value.name,
                    });
                    if (index == criteria.length - 1) {
                        let option = this.findOption({
                            ...this.selected,
                            [criterion.name]: value.name,
                        });
                        if (option) {
                            let { price = 0 } = option;
                            if (price != 0) {
                                let prefix = "";
                                if (0 < price) prefix = "+";
                                if (price < 0) prefix = "-";
                                text += ` (${prefix}${price.format?.()}원)`;
                            }
                        }
                    }
                    if (shows) values.push({ ...value, text });

                    return values;
                }, []),

                ////////////////////////////////////////////////////////////////
                // 이전 셀렉트 미지정 시 비활성화
                ////////////////////////////////////////////////////////////////
                disabled: index != 0 && !this.selected[criteria[index - 1]?.name],
            }));
        },
    },
    methods: {
        sync() {
            this.selected = { ...this.value };
        },
        emit(index) {
            const names = [...[...this.items].slice(0, index + 1), ...[this.color].filter((item) => item)].map(({ name }) => name);
            for (const name of Object.keys(this.selected)) {
                if (!names.includes(name)) delete this.selected[name];
            }
            this.$emit("input", this.selected);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
// btn-group
.btn-group {
    flex-wrap: wrap;
    margin: -4px;
    .v-btn {
        line-height: 1;
        opacity: 1;
        &.v-size--x-small.v-btn--icon {
            position: relative;
            width: 34px !important;
            height: 34px !important;
            padding: 0 !important;
        }
    }
    &--color {
        .v-btn {
            border-radius: 100% !important;
            overflow: hidden;
            border: 0 !important;
            &--active {
                &::before {
                    display: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    border: 2px solid var(--v-grey-darken4) !important;
                }
            }
        }
    }
    &__colorchip {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
}
</style>
