var render = function render(){
  var _vm$product, _vm$product2, _vm$product2$optionsC, _vm$product3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shows ? _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.type) == _vm.PRODUCT_TYPES.NORMAL_PRODUCT.value ? [(_vm$product2 = _vm.product) !== null && _vm$product2 !== void 0 && (_vm$product2$optionsC = _vm$product2.optionsConf) !== null && _vm$product2$optionsC !== void 0 && _vm$product2$optionsC.enabled ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('options-colors', _vm._b({
    on: {
      "input": _vm.push__option
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'options-colors', {
    criteria: _vm.criteria,
    findOption: _vm.findOption,
    someOption: _vm.someOption
  }, false)), _c('options-others', _vm._b({
    on: {
      "input": _vm.push__option
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'options-others', {
    criteria: _vm.criteria,
    findOption: _vm.findOption,
    someOption: _vm.someOption
  }, false))], 1) : _vm._e()] : _vm._e(), ((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.type) == _vm.PRODUCT_TYPES.VARIETY_BUNDLE.value ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('options-variation', _vm._g(_vm._b({}, 'options-variation', {
    product: _vm.product
  }, false), {
    push: _vm.push
  }))], 1)] : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('options-choicees', _vm._b({
    on: {
      "input": function (carts) {
        return _vm.$emit('input', carts);
      }
    }
  }, 'options-choicees', {
    value: _vm.value,
    product: _vm.product
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('options-relateds', _vm._b({
    on: {
      "input": function (carts) {
        return _vm.$emit('input', carts);
      }
    }
  }, 'options-relateds', {
    value: _vm.value,
    product: _vm.product
  }, false))], 1)], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }