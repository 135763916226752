var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.choicees.length && _vm.enabled ? _c('div', [_c('tit-wrap-x-small', {
    attrs: {
      "title": "필수선택"
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "return-object": "",
      "placeholder": "필수상품선택",
      "attach": "",
      "outlined": "",
      "hide-details": "",
      "disable-lookup": "",
      "items": _vm.choicees
    },
    on: {
      "change": _vm.emit
    }
  }, 'v-select', {
    itemText: _vm.itemText,
    itemDisabled: _vm.itemDisabled
  }, false))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }