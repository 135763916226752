var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-x-small', {
    attrs: {
      "title": "배송선택"
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "attach": "",
      "placeholder": "선택",
      "return-object": "",
      "item-value": "_id"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.shippingOption,
      callback: function ($$v) {
        _vm.shippingOption = $$v;
      },
      expression: "shippingOption"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    items: _vm.items
  }), false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }