var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.criterion ? _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "색상선택"
    }
  }), _c('div', {
    staticClass: "pb-12px"
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v(_vm._s(_vm.colorName))])]), _c('v-btn-toggle', {
    staticClass: "btn-group btn-group--color",
    attrs: {
      "group": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.selected[_vm.criterionName],
      callback: function ($$v) {
        _vm.$set(_vm.selected, _vm.criterionName, $$v);
      },
      expression: "selected[criterionName]"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-btn', {
      key: item.name,
      attrs: {
        "value": item.name,
        "x-small": "",
        "icon": ""
      }
    }, [item.img ? [_c('div', {
      staticClass: "square-img",
      style: `background-image: url(${item.img.url})`
    })] : [_c('div', {
      staticClass: "btn-group__colorchip",
      style: `background-color: ${item.hexa}`
    })]], 2)];
  })], 2)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }