<template>
    <info-section class="txt txt--xs" v-if="shows">
        <v-row class="row--xxs">
            <template v-if="product?.type == PRODUCT_TYPES.NORMAL_PRODUCT.value">
                <v-col cols="12" v-if="product?.optionsConf?.enabled">
                    <options-colors v-model="selected" v-bind="{ criteria, findOption, someOption }" @input="push__option" />
                    <options-others v-model="selected" v-bind="{ criteria, findOption, someOption }" @input="push__option" />
                </v-col>
            </template>
            <template v-if="product?.type == PRODUCT_TYPES.VARIETY_BUNDLE.value">
                <v-col cols="12">
                    <options-variation v-bind="{ product }" v-on="{ push }" />
                </v-col>
            </template>
            <!-- <v-col cols="12">
                <options-supplies v-bind="{ value, product }" @input="(carts) => $emit('input', carts)" />
            </v-col> -->
            <v-col cols="12">
                <options-choicees v-bind="{ value, product }" @input="(carts) => $emit('input', carts)" />
            </v-col>
            <v-col cols="12">
                <options-relateds v-bind="{ value, product }" @input="(carts) => $emit('input', carts)" />
            </v-col>
        </v-row>
    </info-section>
</template>

<script>
import { initProduct, PRODUCT_TYPES } from "@/assets/variables";

import InfoSection from "@/components/client/shop/products/view/info/info-section.vue";
import OptionsColors from "./options/options-colors.vue";
import OptionsOthers from "./options/options-others.vue";
// import OptionsSupplies from "./options/options-supplies.vue";
import OptionsChoicees from "./options/options-choicees.vue";
import OptionsRelateds from "./options/options-relateds.vue";
import OptionsVariation from "./options/options-variation.vue";

export default {
    components: {
        InfoSection,
        OptionsColors,
        OptionsOthers,
        // OptionsSupplies,
        OptionsChoicees,
        OptionsRelateds,
        OptionsVariation,
    },
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        selected: {},

        PRODUCT_TYPES,
    }),
    computed: {
        shows() {
            const isOptionEnabled = this.product?.optionsConf?.enabled;
            const isSupplyEnabled = this.product?.suppliesEnabled;
            const isChoiceEnabled = this.product?.choiceesConf?.enabled;
            const isRelateEnabled = this.product?.relatedsConf?.enabled;
            const isVarietyBundle = this.product?.type == PRODUCT_TYPES.VARIETY_BUNDLE.value;

            return isOptionEnabled || isSupplyEnabled || isChoiceEnabled || isRelateEnabled || isVarietyBundle;
        },
        criteria() {
            return this.product?.optionsConf?.criteria || [];
        },
    },
    methods: {
        push(cart) {
            this.$emit("input", [...this.value, cart]);
        },
        push__option() {
            const option = this.findOption();
            if (!option) return;

            if (option.stock < 1) {
                alert("재고가 없는 상품입니다.");
                return;
            }

            const index = this.value.findIndex(({ _option }) => _option == option?._id);
            if (-1 < index) {
                // const carts = [...this.value];
                // carts[index].amount += 1;
                // this.$emit("input", carts);
                alert("이미 담겨진 옵션입니다.");
                this.initSelected();
            } else {
                const cart = {
                    _seller: this.product?._seller,
                    _product: this.product?._id,
                    _option: option?._id,
                    name: option?.name,
                    price: option?.price,
                    salePrice: option?.price + this.product.salePrice,
                    discountPrice: this.product.discountPrice,
                    stock: option?.stock,
                    amount: 1,
                };
                this.push(cart);
                this.initSelected();
            }
        },
        initSelected() {
            this.criteria.forEach((item) => {
                const key = item.name;
                this.selected[key] = null;
            });
        },
        findOption(selected = this.selected) {
            const keys = this.criteria.map(({ name }) => name);
            const hasItem = !keys.some((key) => !selected[key]);
            if (!hasItem) return;

            const { options = [] } = this.product;
            const optionName = keys.map((key) => `${encodeURIComponent(key)}: ${encodeURIComponent(selected[key])}`).join(" / ");
            return options.find((option) => option.name == optionName);
        },
        someOption(selected = this.selected) {
            const keys = this.criteria.reduce((keys, { name }) => (selected[name] ? [...keys, name] : keys), []);

            const selectedNames = keys.map((key) => `${encodeURIComponent(key)}: ${encodeURIComponent(selected[key])}`);

            const { options = [] } = this.product;
            return options.some((option) => {
                if (!option.enabled) return false;

                let optionNames = option.name.split(" / ");
                return !selectedNames.some((item) => !optionNames.includes(item));
            });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .page-section {
        &:first-child {
            padding-top: 0;
        }
    }
}
</style>
